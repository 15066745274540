import { ApolloError } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import type { IUserTraits } from './types'

type TErrorParams = Record<string, unknown>

export const initDatadog = () => {
  const applicationId = process.env.DATADOG_APPLICATION_ID
  const clientToken = process.env.DATADOG_CLIENT_TOKEN

  if (applicationId && clientToken) {
    datadogRum.init({
      applicationId,
      clientToken,
      env: process.env.VERCEL_ENV || 'development',
      version: process.env.SHORT_SHA,
      site: 'datadoghq.com',
      service: 'settle-web',
      sampleRate: 100,
      trackInteractions: true,
      trackFrustrations: true,
      allowedTracingOrigins: [process.env.BACKEND_APP_URL],
    })

    datadogRum.startSessionReplayRecording()
  }
}

export const addAction = (name: string, context?: Record<string, unknown>) => {
  datadogRum.addAction(name, context)
}

export const updateUser = (userId?: string | null, traits: IUserTraits = {}) => {
  datadogRum.setUser({
    id: userId ?? undefined,
    userId,
    ...traits,
  })
}

export const logError = (err: Error, params?: TErrorParams): void => {
  datadogRum.addError(err, params)
}

export const logApolloError = (err: ApolloError, params?: TErrorParams): void => {
  err.graphQLErrors.forEach(({ message }) => logError(new Error(message), params))
}

export const generateDDTiming = (key: string) => {
  let startTime: number | null = null

  return {
    start: () => {
      startTime = Date.now()
    },
    clear: () => {
      startTime = null
    },
    end: () => {
      if (startTime) {
        const endTime = Date.now()
        const duration = endTime - startTime

        datadogRum.addTiming(key, duration)
        startTime = null

        return duration
      }
      return null
    },
  }
}
