import { useQuery } from '@apollo/client'
import { useConnectedAccountingPlatform } from 'lib/hooks/useConnectedAccountingPlatform/useConnectedAccountingPlatform'
import { getAccountingPlatformName } from 'lib/helpers/getAccountingPlatformName/getAccountingPlatformName'
import { useBusiness } from 'lib/hooks/useBusiness/useBusiness'
import { AccountingPlatformHookDocument } from './useAccountingPlatform.graphql'
import { IUseAccountingPlatform } from './useAccountingPlatform.types'
import { ProviderEnum, SyncStateEnum } from 'gql/types'

export const useAccountingPlatform = (): IUseAccountingPlatform => {
  const businessQuery = useBusiness()
  const { business } = businessQuery
  const { data, loading } = useQuery(AccountingPlatformHookDocument, {
    errorPolicy: 'all', // doesn't throw an error when user doesn't have permission to read accounting state
  })
  const { quickBooksState, xeroState, netsuiteState, accountingState, finaloopState } = data ?? {}
  const syncState =
    (business?.xeroLinked && xeroState?.syncState) ||
    (business?.netsuiteLinked && netsuiteState?.syncState) ||
    (business?.quickBooksLinked && quickBooksState?.syncState) ||
    null
  const syncErrorMessage =
    (business?.xeroLinked && xeroState?.syncErrorMessage) ||
    (business?.netsuiteLinked && netsuiteState?.syncErrorMessage) ||
    (business?.quickBooksLinked && quickBooksState?.syncErrorMessage) ||
    null
  const connectedAccountingPlatform = useConnectedAccountingPlatform()
  const disconnectedAccountingPlatform = (() => {
    if (connectedAccountingPlatform) {
      return null
    }

    return accountingState?.accountingProvider ?? null
  })()

  const autoImportBills = (() => {
    if (connectedAccountingPlatform === ProviderEnum.QUICK_BOOKS) {
      return quickBooksState?.autoImportBills ?? null
    }
    if (connectedAccountingPlatform === ProviderEnum.NETSUITE) {
      return netsuiteState?.autoImportBills ?? null
    }

    return null
  })()

  const syncInProgress = syncState === SyncStateEnum.SYNC_IN_PROGRESS || syncState === SyncStateEnum.READY_TO_SYNC
  const lastConnectedAccountingPlatform = connectedAccountingPlatform || disconnectedAccountingPlatform
  const accountingPlatformName = getAccountingPlatformName(lastConnectedAccountingPlatform)

  const isReadOnly: IUseAccountingPlatform['isReadOnly'] = (() => {
    switch (connectedAccountingPlatform) {
      case ProviderEnum.QUICK_BOOKS:
        return quickBooksState?.readOnly ?? false
      case ProviderEnum.XERO:
        return xeroState?.readOnly ?? false
      case ProviderEnum.NETSUITE:
        return netsuiteState?.readOnly ?? false
      default:
        return false
    }
  })()

  const syncAutomatically = (() => {
    switch (connectedAccountingPlatform) {
      case ProviderEnum.QUICK_BOOKS:
        return quickBooksState?.syncAutomatically ?? false
      case ProviderEnum.XERO:
        return xeroState?.syncAutomatically ?? false
      case ProviderEnum.NETSUITE:
        return netsuiteState?.syncAutomatically ?? false
      default:
        return false
    }
  })()

  const syncedAt: IUseAccountingPlatform['syncedAt'] = (() => {
    switch (connectedAccountingPlatform) {
      case ProviderEnum.QUICK_BOOKS:
        return quickBooksState?.syncedAt ?? null
      case ProviderEnum.XERO:
        return xeroState?.syncedAt ?? null
      case ProviderEnum.NETSUITE:
        return netsuiteState?.syncedAt ?? null
      default:
        return null
    }
  })()

  const state = (() => {
    switch (connectedAccountingPlatform) {
      case ProviderEnum.QUICK_BOOKS:
        return quickBooksState
      case ProviderEnum.XERO:
        return xeroState
      case ProviderEnum.NETSUITE:
        return netsuiteState
      case ProviderEnum.FINALOOP:
        return finaloopState
      default:
        return null
    }
  })()

  return {
    connectedAccountingPlatform,
    disconnectedAccountingPlatform,
    loading: loading || businessQuery.loading,
    syncState,
    syncInProgress,
    lastConnectedAccountingPlatform,
    accountingPlatformName,
    syncErrorMessage,
    syncAutomatically,
    isReadOnly,
    syncedAt,
    autoImportBills,
    state: state || null,
  }
}
