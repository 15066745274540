import { FieldReadFunction } from '@apollo/client'
import type { CatalogItem, CatalogItemVariation } from 'gql/types'

/**
 * Filters out empty `purchaseDetails` entries from `CatalogItem` and `CatalogItemVariation` types.
 * An entry is considered empty (placeholder) if it doesn't have `vendorLocalBusiness` set.
 *
 * Rationale:
 * 1. New catalog items/variations shouldn't have empty purchase sources by default.
 * 2. Backend creates placeholder purchase details for QBO sync's description field.
 * 3. These empty entries lead to UI confusion and clutter.
 *
 * Note: Temporary solution until Q1 2025, when backend will decouple description field from
 * placeholder `purchaseDetails`.
 */
export const filterEmptyPurchaseDetails: FieldReadFunction<
  CatalogItem['purchaseDetails'] | CatalogItemVariation['purchaseDetails']
> = (existing, { readField }) => {
  if (existing) {
    return existing.filter((ref) => readField('vendorLocalBusiness', ref) !== null)
  }

  return existing
}
