import { Trans } from 'react-i18next'
import NextLink from 'next/link'
import { Typography } from '@mui/material'
import { useRouter } from 'next/router'
import { Link } from 'settle-ui/components/Link/Link'
import { logEvent } from 'lib/tracking/segment'
import { payerInvitationRoutes } from 'lib/navigation/helpers/getPayerSignUpPath/getPayerSignUpPath'
import * as events from '../../LoginPage.events'

export const CreateAccountLink = () => {
  const router = useRouter()
  const handleClick = () => logEvent(events.CREATE_ACCOUNT_LINK_CLICK)

  return (
    <Typography variant="body2">
      <Trans
        i18nKey="loginPage.createAccount_v2"
        components={{
          signUp: (
            <Link
              variant="inherit"
              component={NextLink}
              onClick={handleClick}
              href={{
                query: router.query,
                pathname: payerInvitationRoutes.product,
              }}
            />
          ),
        }}
      />
    </Typography>
  )
}
