import { useMountEffect, useTimeoutEffect } from '@react-hookz/web'
import noop from 'lodash/noop'
import Fade from '@mui/material/Fade'
import CloseIcon from '@mui/icons-material/Close'
import type { TButtonProps } from 'settle-ui/components/Button/Button'
import { TSnackbarVariant } from 'settle-ui/hooks/useSnackbarsStore/useSnackbarsStore.types'
import * as $ from './Snackbar.styled'

export interface ISnackbarButton {
  id?: string
  text: string
  onClick(): void
  sx?: TButtonProps['sx']
  disabled?: boolean
}

interface ISnackbar {
  id?: string
  message: React.ReactNode
  timeout?: number
  closable?: boolean
  spinner?: boolean
  variant: TSnackbarVariant
  buttons?: Maybe<ISnackbarButton>[]
  permanent?: boolean
  onClose(): void
  onMount?(): void
  onCloseClick?(): void
  onMouseEnter?(): void
  onMouseLeave?(): void
}

export const Snackbar: React.FC<ISnackbar> = ({
  id,
  message,
  timeout = 5000,
  closable = true,
  spinner,
  variant,
  buttons,
  permanent = false,
  onMount = noop,
  onClose,
  onCloseClick,
  onMouseEnter,
  onMouseLeave,
}) => {
  useMountEffect(onMount)

  useTimeoutEffect(onClose, timeout <= 0 || permanent ? undefined : timeout)

  return (
    <Fade in={true}>
      <$.Root data-test-id={`snackbar-${variant}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        <$.Snackbar variant={variant} id={id} data-test-id={id} spacing={1} direction="row">
          {spinner && <$.Spinner color="inherit" size={24} />}
          <$.Message data-test-id={id && `${id}-text`} variant="body2">
            {message}
          </$.Message>
          {buttons?.map((button, i) => {
            if (!button) {
              return null
            }

            return (
              <$.Button
                key={button.id ?? `button-${i}`}
                size="small"
                data-test-id={id && `${id}-button`}
                // eslint-disable-next-line react/jsx-handler-names
                onClick={button.onClick}
                sx={button.sx}
                disabled={button.disabled}
              >
                {button.text}
              </$.Button>
            )
          })}
          {closable && (
            <$.Close
              onClick={() => {
                onClose()
                onCloseClick?.()
              }}
              data-test-id="close"
              color="inherit"
              size="small"
            >
              <CloseIcon />
            </$.Close>
          )}
        </$.Snackbar>
      </$.Root>
    </Fade>
  )
}
