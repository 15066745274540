// eslint-disable-next-line settle/preferred-modules
import { styled, TextField as MuiTextField } from '@mui/material'
import * as AutocompleteInputAdornmentStyled from 'settle-ui/components/Autocomplete/components/AutocompleteInputAdornment/AutocompleteInputAdornment.styled'
import { youShallNotPass } from 'lib/helpers/youShallNotPass/youShallNotPass'
import * as tkns from '../../tokens/common/Input'

export interface ITextFieldStyledProps {
  warning?: boolean
}

export const TextField = styled(MuiTextField, {
  shouldForwardProp: youShallNotPass('warning'),
})<ITextFieldStyledProps>(({ theme, warning, size }) => ({
  '& .MuiInputBase-multiline': {
    padding: 0,
    textAlign: 'start',
  },
  '& .MuiInputBase-input': {
    boxSizing: 'border-box',
    ...(size === 'small' && {
      padding: '4px 8px',
      minHeight: tkns.InputBaseSizeSmallMinHeight,
    }),
    ...(size === 'medium' && {
      padding: '8px 12px',
      minHeight: tkns.InputBaseSizeMediumMinHeight,
    }),
    ...(size === 'large' && {
      padding: '12px 16px',
      minHeight: tkns.InputBaseSizeLargeMinHeight,
    }),
  },
  '& .MuiInputLabel-root': {
    ...(size === 'small' && {
      transform: 'translate(8px, 6px) scale(1)',
    }),
    ...(size === 'medium' && {
      transform: 'translate(12px, 10px) scale(1)',
    }),
    ...(size === 'large' && {
      transform: 'translate(16px, 14px) scale(1)',
    }),
  },
  '& .MuiInputLabel-root.MuiInputLabel-shrink': {
    ...(size === 'small' && {
      transform: 'translate(8px, -9px) scale(0.75)',
    }),
    ...(size === 'medium' && {
      transform: 'translate(12px, -9px) scale(0.75)',
    }),
    ...(size === 'large' && {
      transform: 'translate(16px, -9px) scale(0.75)',
    }),
  },
  '& .MuiOutlinedInput-notchedOutline': {
    ...(size === 'small' && {
      paddingLeft: '2px',
    }),
    ...(size === 'medium' && {
      paddingLeft: '6px',
    }),
    ...(size === 'large' && {
      paddingLeft: '10px',
    }),
  },
  ...(warning && {
    '& .MuiOutlinedInput-root:not(.Mui-focused):not(.Mui-disabled)': {
      '& fieldset, &:hover fieldset': {
        borderColor: theme.tokens.ThemeColorCommonWarning,
      },
    },
    '& .MuiFormHelperText-root': {
      color: theme.tokens.ThemeColorCommonWarning,
    },
    '& label': {
      color: theme.tokens.ThemeColorCommonWarning,
    },
  }),
  '&:hover': {
    [`& ${AutocompleteInputAdornmentStyled.EditButton}`]: {
      display: 'flex',
    },
  },
}))
