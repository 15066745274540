import { useMemo } from 'react'
import { useMembershipRole } from 'lib/hooks/useMembershipRole/useMembershipRole'
import { useBusiness } from 'lib/hooks/useBusiness/useBusiness'
import { useBusinessTraits } from 'lib/hooks/useBusinessTraits/useBusinessTraits'
import { useAccountingPlatform } from 'lib/hooks/useAccountingPlatform/useAccountingPlatform'
import { getProfileTraits } from 'lib/helpers/getProfileTraits/getProfileTraits'
import { isImpersonationSession } from 'lib/helpers/isImpersonationSession/isImpersonationSession'
import { useCountersHook } from 'lib/hooks/useCounters/useCounters.graphql'
import { useProfileHook } from 'lib/hooks/gql/useProfileHook.graphql'

export const useUserTraits = () => {
  const { business } = useBusiness()
  const { data: countersData } = useCountersHook()
  const { data: profileData } = useProfileHook()
  const profile = profileData?.profile
  const membershipRole = useMembershipRole()
  const impersonationSession = isImpersonationSession()
  const businessTraits = useBusinessTraits()
  const accountingPlatform = useAccountingPlatform()

  return useMemo(() => {
    if (!business || !profile || !countersData || !membershipRole || !businessTraits) {
      return null
    }

    return {
      ...businessTraits,
      ...getProfileTraits(profile),
      impersonationSession,
      accountingPlatformReadOnly: accountingPlatform.isReadOnly,
      accountingPlatformSyncAutomatically: accountingPlatform.syncAutomatically,
      // we track authProvider separately, not as a part of getProfileTraits
      // to avoid issue in useAcceptVendorInvitation when accept mutation is crashed
      authProvider: profile.authProvider,
      membershipRole,
      payableInvoicesCount: countersData.counters.all,
      receivableInvoicesCount: countersData.counters.receivableInvoices,
    }
  }, [
    business?.id,
    profile?.id,
    accountingPlatform.isReadOnly,
    accountingPlatform.syncAutomatically,
    countersData,
    membershipRole,
    impersonationSession,
    businessTraits,
  ])
}
