import { useState } from 'react'
import { useSnackbarsStore } from 'settle-ui/hooks/useSnackbarsStore/useSnackbarsStore'
import { ISnackbar } from 'settle-ui/hooks/useSnackbarsStore/useSnackbarsStore.types'
import { Snackbar } from '../Snackbar/Snackbar'
import * as $ from './Snackbars.styled'

interface ISnackbarsProps {
  onMount?(snackbar: ISnackbar): void
  onUnmount?(snackbar: ISnackbar): void
  onMouseEnter?(snackbar: ISnackbar): void
  onMouseLeave?(snackbar: ISnackbar): void
  onActionClick?(snackbar: ISnackbar): void
  onCloseClick?(snackbar: ISnackbar): void
}

export const Snackbars: React.FC<ISnackbarsProps> = ({
  onMount,
  onUnmount,
  onMouseEnter,
  onMouseLeave,
  onActionClick,
  onCloseClick,
}) => {
  const [preventAutoclose, setPreventAutoclose] = useState(false)
  const snackbars = useSnackbarsStore((store) => store.snackbars)
  const hideSnackbar = useSnackbarsStore((store) => store.hideSnackbar)

  return (
    <$.Root
      onMouseEnter={() => {
        setPreventAutoclose(true)
      }}
      onMouseLeave={() => {
        setPreventAutoclose(false)
      }}
    >
      {snackbars.map((snackbar) => (
        <Snackbar
          id={snackbar.id}
          key={snackbar.key}
          message={snackbar.message}
          variant={snackbar.variant}
          timeout={snackbar.options?.timeout}
          closable={snackbar.options?.closable}
          spinner={snackbar.options?.spinner}
          permanent={preventAutoclose || snackbar.options?.permanent}
          onMount={() => {
            onMount?.(snackbar)
          }}
          onMouseEnter={() => {
            onMouseEnter?.(snackbar)
          }}
          onMouseLeave={() => {
            onMouseLeave?.(snackbar)
          }}
          onCloseClick={() => {
            onCloseClick?.(snackbar)
          }}
          onClose={() => {
            onUnmount?.(snackbar)
            hideSnackbar(snackbar.key)
            snackbar.options?.onClose?.()
          }}
          buttons={
            snackbar.options?.buttons
              ? snackbar.options.buttons.map((button) => {
                  if (!button) {
                    return null
                  }

                  return {
                    ...button,
                    onClick: () => {
                      onActionClick?.(snackbar)
                      button.onClick?.()
                      hideSnackbar(snackbar.key)
                    },
                  }
                })
              : [
                  ...(snackbar.options?.text
                    ? [
                        {
                          text: snackbar.options.text,
                          onClick() {
                            onActionClick?.(snackbar)
                            snackbar.options?.onClick?.()
                            hideSnackbar(snackbar.key)
                          },
                        },
                      ]
                    : []),
                ]
          }
        />
      ))}
    </$.Root>
  )
}
