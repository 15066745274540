import { isString, trim } from 'lodash'
import { forwardRef, useCallback } from 'react'
// eslint-disable-next-line settle/preferred-modules
import type { TextFieldProps } from '@mui/material'
import * as $ from './TextField.styled'
import type { ITextFieldStyledProps } from './TextField.styled'

export interface ITextFieldProps extends Omit<TextFieldProps, 'error'>, ITextFieldStyledProps {
  'data-test-id'?: string
  error?: string | boolean
}

export const TextField = forwardRef<HTMLDivElement, ITextFieldProps>(
  ({ id, warning, children, size = 'medium', error, helperText, value, onBlur, onChange, onPaste, ...props }, ref) => {
    const handleBlur: NonNullable<ITextFieldProps['onBlur']> = useCallback(
      (e) => {
        const trimmedValue = trim(e.target.value)
        const event = { ...e, target: { ...e.target, value: trimmedValue } }

        onBlur?.(event)

        if (value !== trimmedValue) {
          onChange?.(event)
        }
      },
      [onBlur, onChange, value],
    )

    const handlePaste: NonNullable<ITextFieldProps['onPaste']> = useCallback(
      async (e) => {
        if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
          e.preventDefault()
          const value = trim(e.clipboardData?.getData('text/plain') || (await navigator.clipboard.readText()))

          e.target.setRangeText(value, e.target.selectionStart ?? 0, e.target.selectionEnd ?? value.length, 'end')
          e.target.dispatchEvent(new Event('input', { bubbles: true }))
        }

        onPaste?.(e)
      },
      [onPaste],
    )

    return (
      <$.TextField
        id={id}
        data-test-id={id}
        size={size}
        value={value}
        error={Boolean(error)}
        helperText={isString(error) ? error : helperText}
        {...(warning && { 'data-warning': true, warning })}
        {...props}
        onPaste={handlePaste}
        onChange={onChange}
        onBlur={handleBlur}
        ref={ref}
      >
        {children}
      </$.TextField>
    )
  },
)

TextField.displayName = 'TextField'
