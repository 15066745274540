export const localStorageKeys = {
  gridColumnsDimensions: 'grid_columns_dimensions',
  businessId: 'business_id',
  invoiceDetailsVendorOCRDismissed: 'invoice_details_perspective_vendor_dismissed',
  plaidLinkToken: 'plaid_link_token',
  plaidPublicInvoiceUUID: 'plaid_public_invoice_uuid',
  plaidConnectionFlow: 'plaid_connection_flow',
  plaidOAuthRedirectUrl: 'plaid_oauth_redirect_url',
  payerReceivablesGroupOpen: 'payer_receivables_group_open',
  payerPayablesGroupOpen: 'payer_payables_group_open',
  purchasingGroupOpen: 'purchasing_group_open',
  moreGroupOpen: 'more_group_open',
  vendorReceivablesGroupOpen: 'vendor_receivables_group_open',
  desktopNavigationDrawerOpen: 'desktop_navigation_drawer_open',
  searchByVendorNameSuggestions: 'search_by_vendor_name_suggestions',
  defaultTableGroup: {
    payables: 'initial_table_group_payables',
    receivables: 'initial_table_group_receivables',
    payments: 'initial_table_group_payments',
    purchaseOrders: 'initial_table_group_purchase_orders',
    catalog: 'initial_table_group_catalog',
  },
  tableTabsStore: {
    payables: 'payables-tabs',
    payments: 'payments-tabs',
    purchaseOrders: 'purchase-orders-tabs',
    receivables: 'receivables-tabs',
    inventoryTransfers: 'inventory-transfers-tabs',
    salesOrders: 'sales-orders-tabs',
    workOrders: 'work-orders-tabs',
  },
  tableExpandGroupsStateStore: {
    inventory: 'inventory-expand-groups',
  },
  tableColumns: {
    vendors: 'vendors_table_columns',
    purchasers: 'purchasers_table_columns',
    purchaseOrders: 'purchase_orders_table_columns',
    receipts: 'receipts_table_columns',
    payments: {
      all: 'payments_columns_all',
      scheduled: 'payments_columns_scheduled',
      initiated: 'payments_columns_initiated',
      paid: 'payments_columns_paid',
      failed: 'payments_columns_failed',
      inReview: 'payments_columns_in_review',
      onHold: 'payments_columns_on_hold',
      canceled: 'payments_columns_canceled',
    },
  },
  dataGridState: {
    vendors: 'vendors_data_grid_state',
    purchasers: 'purchasers_data_grid_state',
    receivables: 'receivables_data_grid_state',
    clientManagerTeamMembers: 'client-manager-team-members',
    clientManagerClients: 'client-manager-clients',
  },
  dashboardWidgetsExpanded: 'dashboard_widgets_expanded',
  receivableSyncMessageDismissed: 'receivable_sync_message_dismissed',
  tabs: {
    receivables: 'receivables_visible_tabs',
    payables: 'payables_visible_tabs',
    payments: 'payments_visible_tabs',
    catalog: 'catalog_visible_tabs',
  },
  processingReportsStatusSnackbar: 'processing_reports_status_snackbar',
  mfaToken: 'mfa_token',
  mfaEmail: 'mfa_email',
  hideOpenInQBOWarn: 'hide_open_in_qbo_warn',
  redirectToPathAfterAccountingConnection: 'redirect_to_path_after_accounting_connection',
  paymentPaginationPage: 'payment_pagination_page',
  payablesPaginationPage: 'payables_pagination_page',
  payablesNavigationWithinPage: 'payables_navigation_within_page',
  receivablesPaginationPage: 'receivables_pagination_page',
  receivablesPaginationVariables: 'payables_pagination_variables',
  vendorCreditsNavigationWithinPage: 'vendor_credits_navigation_within_page',
  hasLendingJourneyToDos: 'has_lending_journey_to_dos',
  hasBizDetailsSubmitted: 'has_biz_details_submitted',
  displayReferralNavItem: 'display_referral_nav_item',
  viewedSettings: 'viewed_settings',
  dontShowMatchingGuide: 'dont_show_matching_guide',
  guidedPurchaseOrderItemizationBubbles: 'guided_purchase_order_itemization_bubbles',
  guidedReceiptItemizationBubbles: 'guided_receipt_itemization_bubbles',
  guidedBillItemizationBubbles: 'guided_bill_itemization_bubbles',
  similarCatalogItemsFoundBubble: 'similar_catalog_items_found_bubble',
  makeStandalonePaymentBubble: 'make_standalone_payment_bubble',
  refreshedCatalogBubble: 'catalog_v2_bubble',
  itemizeLinkedDocumentBubble: 'itemize_linked_document_bubble',
  documentCommentsButtonBubble: 'document_comments_button_bubble',
  apForecastBetaEnabled: 'ap_forecast_beta_enabled',
  discrepancyRulesUpsellBubble: 'discrepancy_rules_upsell_bubble',
  additionalExpensesUpsellBubble: 'additional_expenses_upsell_bubble',
  payableDialogSettings: 'payable_dialog_settings',
  purchaseOrderDialogSettings: 'purchase_order_dialog_settings',
  receiptDialogSettings: 'receipt_dialog_settings',
  receivableDialogSettings: 'receivable_dialog_settings',
  catalogItemDialogSettings: 'catalog_item_dialog_settings',
  wmsNudgeDismissed: 'wms_nudge_dismissed',
  inventoryTransferDocumentPreviewBubble: 'inventory_transfer_document_preview_bubble',
  catalogItemSalesSourceShopifyItems: 'catalog_item_sales_source_shopify_items',
  catalogItemSalesSourceWMSItems: 'catalog_item_sales_source_wms_items',
  catalogItemPurchaseSourceItems: 'catalog_item_purchase_source_items_',
  forceHideSyncInventoryWidget: 'force_hide_sync_inventory_widget',
  shopifyOnboardingBusinessId: 'shopify_onboarding_business_id',
  shopifyOnboardingSelectedPlan: 'shopify_onboarding_selected_plan',
} as const

export const sessionStorageKeys = {
  featureSessionLimit: 'feature_session_limit',
  matchingGuideShown: 'matching_guide_shown',
  matchingFeedbackButtonsShown: 'matching_feedback_buttons_shown',
  isDemoScheduled: 'is_demo_scheduled',
  isConnectShopifyNudgeDismissed: 'is_connect_shopify_nudge_dismissed',
  doNotShowFinNudgesBanner: 'do_not_show_financial_nudegs_banner',
  doNotShowFinNudgesMessage: 'do_not_show_financial_nudges_message',
  doNotShowInviteVendorsBanner: 'do_not_show_invite_vendors_banner',
  doNotShowRelatedPaymentBanner: 'do_not_show_related_payment_banner',
  receiptFormDetailsOpen: 'receipt_form_details_open',
  poFormDetailsOpen: 'po_form_details_open',
  poFormDeliveryOpen: 'po_form_delivery_open',
  poFormContactDetailsOpen: 'po_form_contact_details_open',
  poFormApForecastOpen: 'po_form_ap_forecast_open',
  billFormLinkedDocsOpen: 'bill_form_linked_docs_open',
  billFormDetailsOpen: 'bill_form_details_open',
  billFormSummaryOpen: 'bill_form_summary_open',
  linkedToReceiptDocsSectionOpen: 'linked_to_receipt_docs_section_open',
  receiptDeliverySectionOpen: 'receipt_delivery_section_open',
  docsLinkedInvoicesListOpen: 'docs_tab_linked_invoices_list_open',
  docsLinkedReceiptsListOpen: 'docs_tab_linked_receipts_list_open',
  docsLinkedInventoryTransfersListOpen: 'docs_tab_linked_inventory_transfers_list_open',
  docsLinkedPurchaseOrderListOpen: 'docs_tab_linked_purchase_order_list_open',
  docsLinkedWorkOrdersListOpen: 'docs_tab_linked_work_order_list_open',
  docsOtherAttachmentsOpen: 'docs_tab_other_attachments_open',
  inventoryTransferDetailsSectionOpen: 'inventory_transfer_details_section_open',
  inventoryTransferTransferredItemsSectionOpen: 'inventory_transfer_transferred_items_section_open',
  inventoryTransferAdditionalExpensesSectionOpen: 'inventory_transfer_additional_expenses_section_open',
  workOrderAdditionalExpensesSectionOpen: 'work_order_additional_expenses_section_open',
  locationDialogDetailsSectionOpen: 'location_dialog_details_section_open',
  locationDialogAddressSectionOpen: 'location_dialog_address_section_open',
  unlinkedCatalogItemsDismissed: 'unlinked_catalog_items_dismissed',
  unlinkedReceiptsDismissed: 'unlinked_receipts_dismissed',
  unreviewedSalesChannelConfigurationsDismissed: 'unreviewed_sales_channel_configurations_dismissed',
  salesOrderDetailsSectionOpen: 'sales_order_details_section_open',
  salesOrderStatusSectionOpen: 'sales_order_status_section_open',
  purchaseOrderLinkedPayment: 'purchase-order-linked-bills-payments',
  catalogItemSections: {
    detailsOpen: 'catalog_item_details_section_open',
    purchaseSourcesOpen: 'catalog_item_purchase_section_open',
    salesChannelsOpen: 'catalog_item_sales_channels_open',
    barcodesOpen: 'catalog_item_barcodes_open',
    salesChannelsWmsSectionOpen: 'catalog_item_sales_channels_wms_section_open',
    propertiesOpen: 'catalog_item_properties_section_open',
    billOfMaterialsOpen: 'catalog_item_bill_of_materials_section_open',
    landedCostOpen: 'catalog_item_landed_cost_section_open',
    locationsOpen: 'catalog_item_locations_open',
    baselineCostOpen: 'catalog_item_baseline_cost_section_open',
  },
  workOrderSections: {
    detailsOpen: 'work_order_details_section_open',
    lineItemsOpen: 'work_order_line_items_section_open',
  },
}

export const cookieKeys = {
  token: 'token',
  cin7DataToken: 'cin7_data_token',
  appLayoutNavigationMenu: 'app_layout_navigation_menu',
  suppressedConfirmShopifyPlanBanner: 'suppressed_confirm_shopify_plan_banner',
}
