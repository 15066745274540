import isString from 'lodash/isString'

export const isNextUrlValid = (next: Maybe<string>): next is string => {
  if (!isString(next)) {
    return false
  }

  const urlPattern = new RegExp(
    '^(\\/[-a-z\\d%_.~+]*)*' + // validate path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ) // validate fragment locator

  return urlPattern.test(next)
}
