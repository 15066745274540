import { forwardRef } from 'react'
import { ExtendButtonBase, ButtonBaseTypeMap, CircularProgress, ButtonBaseProps, ButtonProps } from '@mui/material'
import * as $ from './Button.styled'

export interface IProps extends $.TBaseButtonProps {
  /**
   * Props applied to the `StartIconBox` element.
   */
  startIconBoxProps?: React.ComponentProps<typeof $.StartIconBox>

  /**
   * Props applied to the `EndIconBox` element.
   */
  endIconBoxProps?: React.ComponentProps<typeof $.EndIconBox>

  /**
   * If `true`, the button becomes disabled and shows loading indicator as start icon.
   * @default false
   */
  loading?: boolean
  /**
   * href prop from the MUI Button component.
   */
  href?: ButtonProps['href']
  target?: string
  rel?: string
  'data-test-id'?: string
}
export type TButtonProps<D extends React.ElementType<any> = 'button', P = unknown> = ButtonBaseProps<D, IProps & P>

//@ts-expect-error TODO
export const Button: ExtendButtonBase<ButtonBaseTypeMap<IProps>> = forwardRef(
  (
    {
      size = 'medium',
      startIcon,
      startIconBoxProps,
      endIcon,
      endIconBoxProps,
      kind = 'neutral',
      variant = 'secondary',
      children,
      fullWidth,
      loading,
      disabled,
      active,
      ...otherProps
    },
    ref,
  ) => (
    <$.Button
      endIcon={endIcon}
      startIcon={startIcon}
      kind={kind}
      variant={variant}
      size={size}
      fullWidth={fullWidth}
      ref={ref}
      disabled={loading || disabled}
      aria-selected={active}
      active={active}
      {...otherProps}
    >
      {(startIcon || loading) && (
        <$.StartIconBox size={size} {...startIconBoxProps}>
          {loading ? <CircularProgress size={20} color="inherit" /> : startIcon}
        </$.StartIconBox>
      )}
      {children}
      {endIcon && (
        <$.EndIconBox size={size} {...endIconBoxProps}>
          {endIcon}
        </$.EndIconBox>
      )}
    </$.Button>
  ),
)

//@ts-expect-error TODO
Button.displayName = 'Button'
