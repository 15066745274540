import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useSnackbars } from 'settle-ui/hooks/useSnackbars/useSnackbars'
import { ISnackbar } from 'settle-ui/hooks/useSnackbarsStore/useSnackbarsStore.types'

type TUrlBasedSnackbarOptions = Pick<NonNullable<ISnackbar['options']>, 'permanent'>
export type TUrlBasedParams = {
  warning?: string
  error?: string
  alert?: string
  info?: string
}

export const useUrlBasedSnackbar = (options?: TUrlBasedSnackbarOptions) => {
  const router = useRouter()
  const { permanent } = options ?? {}
  const { showWarning, showError, showAlert, showInfo } = useSnackbars()

  useEffect(() => {
    const { warning, error, alert, info } = router.query

    if (!warning && !error && !alert && !info) {
      return
    }

    if (warning) {
      showWarning(warning.toString(), { permanent, id: 'url_based_warning' })

      delete router.query.warning
    }

    if (error) {
      showError(error.toString(), { permanent, id: 'url_based_error' })

      delete router.query.error
    }

    if (alert) {
      showAlert(alert.toString(), { permanent, id: 'url_based_alert' })

      delete router.query.alert
    }

    if (info) {
      showInfo(info.toString(), { permanent, id: 'url_based_info' })

      delete router.query.info
    }

    router.replace({ query: router.query })
  }, [router, showAlert, permanent, showInfo, showError, showWarning])
}
