import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { usePathParamsStore } from 'lib/navigation/hooks/usePathParamsStore/usePathParamsStore'
import { qsStringSchema } from 'lib/zod'

export const usePathParams = () => {
  const {
    query: { businessId = '', uuid = '' },
  } = useRouter()
  const { setBusinessId, setUuid } = usePathParamsStore()

  useEffect(() => {
    setBusinessId(qsStringSchema.parse(businessId))
    setUuid(qsStringSchema.parse(uuid))
  }, [businessId, uuid, setBusinessId, setUuid])
}
