import isBoolean from 'lodash/isBoolean'
import { useEffect, useCallback } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import camelCase from 'lodash/camelCase'

export const useGlobalLDFlagsGetter = () => {
  const flags = useFlags()

  const getLDFlags = useCallback<TGetLDFlags>(
    (...searchedFlags) => {
      if (!searchedFlags.length) {
        return { ...flags }
      }

      return Object.fromEntries(
        Object.entries(flags || {}).filter(([key, value]) =>
          searchedFlags.some((search) =>
            isBoolean(search) ? value === search : search === key || camelCase(search.toString()) === key,
          ),
        ),
      )
    },
    [flags],
  )

  useEffect(() => {
    Object.assign(window, { getLDFlags })
  }, [getLDFlags])
}
