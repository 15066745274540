import { createWithEqualityFn } from 'zustand/traditional'
import { ApolloError } from '@apollo/client'
import reject from 'lodash/reject'
import { shallow } from 'zustand/shallow'
import { getSnackbarWithKey } from './helpers/getSnackbarWithKey/getSnackbarWithKey'
import { DEFAULT_ERROR_TEXT } from './useSnackbarsStore.consts'
import { ISnackbar, TSnackbarOptions, TSnackbarVariant } from './useSnackbarsStore.types'

interface ISnackbarsStore {
  snackbars: ISnackbar[]
  showSnackbar(variant: TSnackbarVariant, message: ISnackbar['message'], options?: TSnackbarOptions): string
  hideSnackbar(key: string): void
  showAlert(message: ISnackbar['message'], options?: TSnackbarOptions): string
  showWarning(message: ISnackbar['message'], options?: TSnackbarOptions): string
  showInfo(message: ISnackbar['message'], options?: TSnackbarOptions): string
  showError(message: ISnackbar['message'], options?: TSnackbarOptions): string
  showApolloError(err: ApolloError, options?: TSnackbarOptions): void
  showApolloOrTextError(err: ApolloError, text?: string, options?: TSnackbarOptions): void
}

export const useSnackbarsStore = createWithEqualityFn<ISnackbarsStore>(
  (set, get) => ({
    snackbars: [],
    showSnackbar: (variant, message, options) => {
      const snackbar = getSnackbarWithKey({ variant, message, options })

      set(({ snackbars }) => ({ snackbars: [...snackbars, snackbar] }))
      return snackbar.key
    },
    hideSnackbar: (key) => set(({ snackbars }) => ({ snackbars: reject(snackbars, { key }) })),
    showAlert: (message, options) => get().showSnackbar('alert', message, options),
    showWarning: (message, options) => get().showSnackbar('warning', message, options),
    showInfo: (message, options) => get().showSnackbar('info', message, options),
    showError: (message, options) => get().showSnackbar('error', message, options),
    showApolloError: (err: ApolloError, options) => {
      err.graphQLErrors.map((m) => m.message).forEach((message) => get().showError(message, options))
    },
    showApolloOrTextError: (err, text = DEFAULT_ERROR_TEXT, options = {}) => {
      const { showError } = get()

      if (err instanceof ApolloError) {
        const messages = err.graphQLErrors.map((m) => m.message)

        if (messages.length) {
          messages.forEach((message) => showError(message, options))
          return
        }
      }

      showError(text)
    },
  }),
  shallow,
)
