interface IOptions {
  search?: Maybe<string>
}

export const payerInvitationRoutes = {
  landing: '/sign-up/payers/landing',
  product: '/sign-up/payers/product',
  businessDetails: '/sign-up/payers/business-details',
  revenue: '/sign-up/payers/revenue',
  createAccount: '/sign-up/payers/create-account',
} as const

export const getPayerSignUpPath = (route: keyof typeof payerInvitationRoutes, options?: IOptions) => {
  const { search } = options ?? {}
  const pathname = payerInvitationRoutes[route]

  return search ? `${pathname}?${search}` : pathname
}
