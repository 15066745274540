import { useCallback } from 'react'
import { ISnackbar } from 'settle-ui/hooks/useSnackbarsStore/useSnackbarsStore.types'
import { logEvent } from 'lib/tracking/segment'
import { getSnackbarLocation, TGetSnackbarLocationProps } from './helpers/getSnackbarLocation/getSnackbarLocation'

export const useLogSnackbarEvent = ({ pathname, query, asPath }: TGetSnackbarLocationProps) =>
  useCallback(
    ({ key, variant, id, message, options }: ISnackbar, event: string) => {
      const locationGroup = getSnackbarLocation({ pathname, query, asPath })
      const actions = [options?.text].concat(options?.buttons?.map((button) => button?.text)).filter(Boolean)
      const hasActions = actions.length > 0

      return logEvent(event, {
        id,
        key,
        variant,
        message,
        actions,
        hasActions,
        locationGroup,
      })
    },
    [pathname, query, asPath],
  )
